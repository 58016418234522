import React, { Component } from 'react'
import { Layout } from 'core/components'
import { AAnimate } from 'shared/components/common'
import { TrackingLayout } from 'modules/parcels/pages'
import { queryParams } from 'core/utils'

type RastreoProps = PageProps
interface RastreoState {
  mounted: boolean
}

interface RastreoParams {
  code: string
}

class Rastreo extends Component<RastreoProps, RastreoState> {
  state = {
    mounted: false
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ mounted: true })
    }, 0)
  }

  render() {
    const {
      pageContext: { locale },
      location
    } = this.props
    const { mounted } = this.state
    let params = queryParams<RastreoParams>(location)
    if (location.state) {
      params = { code: location.state.code }
    }
    return (
      <Layout locale={locale}>
        <AAnimate type="fadeIn" in={mounted}>
          <TrackingLayout code={params.code} />
        </AAnimate>
      </Layout>
    )
  }
}

export default Rastreo
